import React from "react";
export function Buttons() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  function changePage(x) {
    urlParams.set("o", Number(urlParams.get("o")) + x);
    window.location.search = "?" + urlParams.toString();
  }
  function next() {
    changePage(50);
  }
  function prev() {
    changePage(-50);
  }

  return (
    <div className="Buttons">
      <button onClick={prev}>Previous</button>
      <div className="center">
        <img
          src={
            "https://coomer.party/thumbnail/icons/onlyfans" +
            window.location.pathname
          }
          alt={window.location.pathname}
          width="50px"
          height="50px"
        ></img>
        <div>{window.location.pathname.split('/')[1]}</div>
      </div>
      <button onClick={next}>Next</button>
    </div>
  );
}
