/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import VideoWrapper from "./videowraper";

export function CoomerApi() {
  const [hasLoaded, setHasLoaded] = useState();
  const json = useRef();
  const error = useRef();
  useEffect(() => {
    async function fetchData() {
      await fetch("https://cors.wmeluna.workers.dev/?https://coomer.party/api/onlyfans/user" + window.location.pathname + window.location.search)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          json.current = data;
          console.log(json);
          if (json.current === undefined || json.current.length === 0) {
            error.current.outerText = "No more Pages or user not found.."
          } else {
            setHasLoaded(true);
          }
        });
    }
    if (window.location.pathname !== "/") {
      fetchData();
    } else {
      error.current.outerHTML = "<style>.Buttons {display: none !important}</style><div>Username needed in the url...</br>Ex: <a href=\"" + window.location.origin + "/f1nn5ter\">" + window.location.origin + "/f1nn5ter</a></div>"
    }
  }, []);
  return hasLoaded ? (
    json.current.map((element) => {
        return (
            <React.Fragment key={element.id}>
            { ( element.file.name && !element.file.name.includes("jpg")) ?                
                    <VideoWrapper
                    src={"https://coomer.party" + element.file.path}
                    ></VideoWrapper>
                : <></>
            } 
              {element.attachments.map((attach) => {
                if (!attach.name.includes("jpg")) {
                  return (
                    <React.Fragment>
                      <VideoWrapper
                        src={"https://coomer.party" + attach.path}
                      ></VideoWrapper>
                    </React.Fragment>
                  );
                }
              })}
            </React.Fragment>
        );
      
    })
  ) : (
    <div ref={error}> Loading... </div>
  );
}
