import React, { useRef, useState } from 'react';

export function VideoWrapper({ src }) {
  const videoRef = useRef(null);
  const [err, setErrorState] = useState(false);
  const handleClick = () => {
    // Refetch the video by reloading the same source
    setErrorState(false)
    let url = new URL(src)
    if (url.host === "coomer.party") {
      url.host = "coomer.su"
      src = url.toString()
    } else if (url.host === "coomer.su") {
      url.host = "coomer.party"
      src = url.toString()
    }
    console.log(url)
    videoRef.current.src = src;

    videoRef.current.load();
  };
  const handleError = () => {
    let url = new URL(src);
    if (url.host === "coomer.party") {
      url.host = "coomer.su";
      src = url.toString();
      videoRef.current.src = src;
      videoRef.current.load();
      //attempt to bypass 429 by using second domain
    } else {
      let error = videoRef.current.error;
      setErrorState(true);
      if (error.code === 2) handleClick()
      console.log(videoRef.current.error)
      console.log(videoRef.current)
    }
    }
  
  return (
    <div className='videoWrapper' >
      <video
        ref={videoRef}
        src={src}
        alt=""
        controls
        loop
        height="10%"
        width="40%"
        onError={handleError}
      />
        <button onClick={handleClick} style={{ position: 'absolute', top: 0, right: 0 }}>
          Refetch Video
        </button>
        {err && <div onClick={handleClick} className='error'>Shit broke, try refetching</div>}
    </div>
  );
}

export default VideoWrapper;
