import { CoomerApi } from "./api";
import { Buttons } from "./ui";
function App() {
  return (
    <div className="App">
      <Buttons></Buttons>
      <div className="vids">
        <CoomerApi></CoomerApi>
      </div>
    </div>
  );
}

export default App;
